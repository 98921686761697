.tip-box {
  background-color: rgb(255, 255, 255);
  height: 520px;
  width: 500px;
  border-radius: 20px;
  border-top-left-radius: 20px;

  .header {
    background: rgb(224, 224, 224);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: left;

    .header-name {
      display: flex;
      align-items: flex-end;
      justify-content: left;
      color: #424242;

      .name {
        font-weight: 600;
      }

      .branch {
        font-weight: 400;
      }
    }

    .avatar {
      display: flex;
      align-items: flex-end;
      justify-content: right;
      position: relative;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-size: 24px;

    .number-input {
      font-size: 44px;
      color: rgb(66, 66, 66);
    }
  }

  .footer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    button {
      height: 30px;
      width: 170px;
      color: white;
    }

    .back-button {
      background: rgb(59, 59, 59);
    }
  }
}

@media screen and (max-width: 768px) {
  .tip-box {
    width: 100%;
    border-radius: 0;

    .header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
